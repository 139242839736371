import fetchData from '../../fetchData';
import googlesetData from '../../googlesetData';

import {
  CreateGoogleUser,
  SingleProductQuery,
  SingleProductByIdQuery,
  AllCategories,
  CategoryProductsQuery,
  CategoryAllProductsQuery,
  CategoryContentQuery,
  RelatedCategoryProductsQuery,
  Templates,
  Cliparts,
  Shapes,
  SingleProductBackgrounds,
  SingleProjectProductBackgrounds,
  Fonts,
  Texts,
  CustomTexts,
  Producttype,
  CustomTextsManual,
  User,
  CustomCss,
  GetAllProducts,
  GetAllProductsWithoutDescription,
  GetAllProductImages,
  GetAllProductsForAlgolia,
  GetPageData,
  GetFAQPage,
  GetTermsData,
  SiteHomepageContent,
  GetFAQItems,
  PaginatedProductsQuery,
  FreePaginatedProductsQuery,
  GetOldRelatedProducts,
  GetNewRelatedProducts,
  SingleModalProductQuery,
} from './queries';

export const googleuser = async (variables = {}) => {
  const data = await googlesetData(CreateGoogleUser, { data: variables }, '');

  return data.create_users_item;
};

export const getHomepageProducts = async (siteName) => {
  const data = await fetchData(SiteHomepageContent, {
    variables: {
      siteName,
    },
  });

  return data.data;
};

export const getProduct = async (variables = {}) => {
  const data = await fetchData(SingleProductQuery, {
    variables,
  });

  return data.data['products'];
};

export const getProductModalDetails = async (variables = {}) => {
  const data = await fetchData(SingleModalProductQuery, {
    variables,
  });

  return data.data['products'];
};

export const getProjectDetailById = async (variables = {}) => {
  const data = await fetchData(SingleProductByIdQuery, {
    variables,
  });
  return data.data.products_by_id;
};

export const getAllCategories = async () => {
  const data = await fetchData(AllCategories, {
    variables: {},
  });

  return data.data;
};

export const getCategoryProducts = async (variables = {}) => {
  const data = await fetchData(CategoryProductsQuery, {
    variables,
  });

  return data.data;
};

export const getAllAdminProducts = async (variables = {}) => {
  const data = await fetchData(CategoryAllProductsQuery, {
    variables,
  });

  return data.data;
};

export const getPaginatedProducts = async (variables = {}) => {
  const data = await fetchData(PaginatedProductsQuery, {
    variables,
  });

  return data.data;
};

export const getFreePaginatedProducts = async (variables = {}) => {
  const data = await fetchData(FreePaginatedProductsQuery, {
    variables,
  });

  return data.data;
};

export const getCategoryContent = async (variables = {}) => {
  const data = await fetchData(CategoryContentQuery, {
    variables,
  });

  return data.data;
};

export const getOldRelatedProducts = async (variables = {}) => {
  const data = await fetchData(GetOldRelatedProducts, {
    variables,
  });

  return data.data;
};

export const getNewRelatedProducts = async (variables = {}) => {
  const data = await fetchData(GetNewRelatedProducts, {
    variables,
  });

  return data.data;
};

export const getRelatedCategoryProducts = async (variables = {}) => {
  const data = await fetchData(RelatedCategoryProductsQuery, {
    variables,
  });

  return data.data;
};

export const getTemplates = async () => {
  const data = await fetchData(Templates, {
    variables: {},
  });

  return data.data;
};

export const getCliparts = async () => {
  const data = await fetchData(Cliparts, {
    variables: {},
  });

  return data.data;
};

export const getShapes = async () => {
  const data = await fetchData(Shapes, {
    variables: {},
  });

  return data.data;
};

export const getSingleProductBackgrounds = async (variables = {}) => {
  const data = await fetchData(SingleProductBackgrounds, {
    variables,
  });

  return data.data.products[0].backgrounds;
};

export const getSingleProjectProductBackgrounds = async (variables = {}) => {
  const data = await fetchData(SingleProjectProductBackgrounds, {
    variables,
  });

  return data.data.products_by_id.backgrounds;
};

export const getFonts = async () => {
  const data = await fetchData(Fonts, {
    variables: {},
  });

  return data.data;
};

export const getTexts = async () => {
  const data = await fetchData(Texts, {
    variables: {},
  });

  return data.data;
};

export const getCustomTexts = async () => {
  const data = await fetchData(CustomTexts, {
    variables: {},
  });

  return data.data;
};

export const getProducttype = async () => {
  const data = await fetchData(Producttype, {
    variables: {},
  });

  return data.data;
};

export const getCustomTextsManual = async () => {
  const data = await fetchData(CustomTextsManual, {
    variables: {},
  });

  return data.data;
};

export const getUser = async (variables = {}) => {
  const data = await fetchData(
    User,
    {
      variables,
    },
    '',
  );

  return data.data;
};

export const getCustomCss = async () => {
  const data = await fetchData(CustomCss, {
    variables: {},
  });

  return data.data;
};

export const getAllProducts = async (domain) => {
  const data = await fetchData(GetAllProducts, {
    variables: {
      domain,
    },
  });

  return data.data;
};

export const getAllProductImages = async () => {
  const data = await fetchData(GetAllProductImages, {
    variables: {},
  });

  return data.data.products;
};

export const getAllProductsWithoutDescription = async () => {
  const data = await fetchData(GetAllProductsWithoutDescription, {
    variables: {},
  });

  return data.data;
};

export const getAllProductsForAlgolia = async () => {
  const data = await fetchData(GetAllProductsForAlgolia, {
    variables: {},
  });

  return data.data;
};

export const getPage = async (variables = {}) => {
  const data = await fetchData(GetPageData, {
    variables,
  });

  return data.data;
};

export const getTerms = async (variables = {}) => {
  const data = await fetchData(GetTermsData, {
    variables,
  });
  return data.data;
};

export const faqPage = async (variables = {}) => {
  const data = await fetchData(GetFAQPage, {
    variables,
  });

  return data.data;
};

export const faqItems = async (variables = {}) => {
  const data = await fetchData(GetFAQItems, {
    variables,
  });

  return data.data.faq_items;
};

export const getProjectById = async (variables = {}) => {
  const data = await fetchData(SingleProductByIdQuery, {
    variables,
  });
  return data.data.products_by_id;
};
