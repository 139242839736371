import fetchData from '../../fetchData';
import setData from '../../setData';
import {
  getCurrentUserById,
  getUsersByStripeId,
  updateCurrentUser,
  GetUserByEmail,
  createNewUser,
  updateUserData,
  verifyOldPassword,
  updateUserTokenData,
  getUser,
  updateUserPasswordData,
  GetUserPassword,
} from './queries';

export const updateUserDetails = async (variables = {}) => {
  const data = await setData(updateCurrentUser, { ...variables }, '');
  return data.update_users_item;
};

export const getUserById = async (variables = {}) => {
  const data = await fetchData(getCurrentUserById, { ...variables }, '');

  return data.data.Users_by_id;
};

export const validateUser = async (variables) => {
  const data = await fetchData(ValidateUserEmail, {
    ...variables,
  });

  return data.data;
};

export const getUserByStripeId = async (variables = {}) => {
  const data = await fetchData(getUsersByStripeId, { ...variables }, '');
  return data.data.Users;
};

export const getUserByEmail = async (variables = {}) => {
  const data = await fetchData(GetUserByEmail, { variables }, '');
  return data.data.Users;
};

export const getUserPassword = async (variables = {}) => {
  const data = await fetchData(GetUserPassword, { variables }, '');

  return data.data.Users;
};

export const createUser = async (newUser) => {
  const data = await setData(createNewUser, { data: newUser }, '');

  return data.create_Users_item;
};

export const updateUser = async (id, userData) => {
  const data = await setData(updateUserData, { data: userData, id }, '');

  return data.update_Users_item;
};

export const verifyPassword = async (oldPassword, newPassword) => {
  const data = await setData(
    verifyOldPassword,
    { hash: oldPassword, password: newPassword },
    '/system',
  );

  return data.utils_hash_verify;
};

export const updateUserToken = async (id, tokenData) => {
  const data = await setData(updateUserTokenData, { data: tokenData, id }, '');
  return data.update_Users_item;
};

export const getUserID = async (token, CurrentDate) => {
  const data = await setData(
    getUser,
    { token: token, CurrentDate: CurrentDate },
    '',
  );
  return data.Users;
};

export const updateUserPassword = async (id, passwordData) => {
  const data = await setData(
    updateUserPasswordData,
    { data: passwordData, id },
    '',
  );
  return data.update_Users_item;
};
