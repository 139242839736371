export const createNewUser = `
#graphql
mutation createNewUser($data: create_Users_input!) {
  create_Users_item(data: $data) {
    id
    email
    first_name
    last_name
    website
    phone_number
    organization_name
    leader_name
    logo
    address_street
    address_city
    address_state
    address_zip_code
    stripe_customer_id
    subscription_end_date
    subscription_status
    hebrew_keyboard
    language_preference
  }
}
`;

export const updateCurrentUser = `
#graphql
mutation updateCurrentUser($data: update_Users_input!, $id: ID!) {
  update_Users_item(data: $data, id: $id) {
    first_name
    last_name
  }
}
`;

export const verifyOldPassword = `
#graphql
mutation verifyPassword($hash: String!, $password: String!) {
  utils_hash_verify(hash: $hash, string: $password)
}
`;

export const getCurrentUserById = `
#graphql
query SingleUserById($user_id: ID!) {
  Users_by_id(id: $user_id) {
    id
    email
    first_name
    last_name
    website
    phone_number
    organization_name
    leader_name
    logo
    address_street
    address_city
    address_state
    address_zip_code
    stripe_customer_id
    subscription_end_date
    subscription_status
    hebrew_keyboard
    language_preference
    status
    email_verification_secret
  }
}
`;

export const getUsersByStripeId = `
#graphql
query UsersByStripeId($stripe_id: String) {
  Users(filter: { stripe_customer_id: { _eq: $stripe_id } }) {
    id
    email
    first_name
    last_name
    website
    phone_number
    organization_name
    leader_name
    logo
    address_street
    address_city
    address_state
    address_zip_code
    stripe_customer_id
    subscription_end_date
    subscription_status
    site_id {
      id
    }
  }
}
`;

export const updateUserSettings = `
#graphql
mutation updateUserAutofill($data: update_directus_users_input!, $id: ID!) {
  update_users_item(data: $data, id: $id) {
    email
  }
}
`;

export const getUser = `
#graphql
query getUser($token: String, $CurrentDate: String) {
  Users(
    filter: { token: { _eq: $token }, tokenExpiry: { _gt: $CurrentDate } }
  ) {
    id
  }
}
`;

export const GetUserByEmail = `
#graphql
query UserByEmail($emailvalue: String, $siteIdvalue: GraphQLStringOrFloat!) {
  Users(
    filter: {
      email: { _eq: $emailvalue }
      site_id: { id: { _eq: $siteIdvalue } }
    }
  ) {
    id
    email
    first_name
    last_name
    website
    phone_number
    organization_name
    leader_name
    logo
    address_street
    address_city
    address_state
    address_zip_code
    stripe_customer_id
    subscription_end_date
    subscription_status
    provider
    status
    role{id}
    email_verification_secret
  }
}
`;

export const GetUserPassword = `
#graphql
query UserPasswordByEmail($emailvalue: String, $siteIdvalue: GraphQLStringOrFloat!) {
  Users(
    filter: {
      email: { _eq: $emailvalue }
      site_id: { id: { _eq: $siteIdvalue } }
    }
  ) {
    id
    email
    password
  }
}
`;

export const updateUserData = `
#graphql
mutation updateUserData($data: update_Users_input!, $id: ID!) {
  update_Users_item(data: $data, id: $id) {
    email
  }
}
`;

export const updateUserTokenData = `
#graphql
mutation updateUserTokenData($data: update_Users_input!, $id: ID!) {
  update_Users_item(data: $data, id: $id) {
    email
  }
}
`;

export const updateUserPasswordData = `
#graphql
mutation updateUserPasswordData($data: update_Users_input!, $id: ID!) {
  update_Users_item(data: $data, id: $id) {
    email
  }
}
`;
